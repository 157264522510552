<template>
  <div
    class="grid gap-y-16 gap-x-12 sm:grid-col-1 lg:grid-cols-2 xl:grid-cols-3"
  >
    <div
      v-for="(card, idx) in cards"
      :key="idx"
      class="bg-white border border-gray-200 shadow-md rounded-b-md"
      @click="activeShift(idx)"
    >
      <div
        class="font-bold border-2 border-gray-500 p-8 text-xl"
        :class="
          idx === activeItem || shiftActive[idx] === idx
            ? `border-teal ${shiftActiveStyle[idx]}`
            : ''
        "
      >
        {{ card.title }}
      </div>
      <ModelDayDoughnutChart
        :index="idx"
        :enabled="idx === activeCard(idx)"
        :chartData="data"
        :key="data"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, toRefs, defineEmits } from "vue";
import ModelDayDoughnutChart from "../charts/ModelDayDoughnutChart.vue";

const emits = defineEmits(["active-shift"]);
const shiftActive = ref([]);
const shiftActiveStyle = ref([]);
const activeCard = (id) => {
  if (shiftActive.value?.length > 0) {
    return shiftActive.value[id];
  } else {
    return activeItem.value;
  }
};

const currentShift = () => {
  const time = new Date().toLocaleTimeString("en-GB", {
    timeZone: "Europe/London",
  });
  const currentHour = parseInt(time);
  if (currentHour >= 8 && currentHour < 11) {
    return 0;
  } else if (currentHour >= 11 && currentHour < 16) {
    return 1;
  } else if (currentHour >= 16 && currentHour < 20) {
    return 2;
  } else {
    emits("active-shift", 2);
  }
};

const activeItem = ref(currentShift());

const activeShift = (id) => {
  shiftActive.value = [];
  shiftActiveStyle.value = [];
  shiftActive.value[id] = id;
  shiftActiveStyle.value[id] = "bg-teal text-white";
  emits("active-shift", shiftActive.value[id]);
};

emits("active-shift", activeItem.value);

onMounted(() => {
  currentShift();
});

const props = defineProps({
  cards: {
    type: Array,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
});

const { cards, data } = toRefs(props);
</script>
