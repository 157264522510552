<template>
  <div>
    <canvas :id="sampleBarConfig.id" class="w-full p-6 h-auto"></canvas>
  </div>
</template>

<script setup>
import Chart from "chart.js";
import { onMounted, ref, defineProps, toRefs, computed } from "vue";

const props = defineProps({
  index: Number,
  enabled: {
    type: Boolean,
    required: true,
  },
  chartData: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const { index } = toRefs(props);

const labels = computed(() => {
  return Object.keys(props.chartData).map((label) => {
    return label.charAt(0).toUpperCase() + label.slice(1);
  });
});

const enabledColors = ["#46c4bb", " #598fff", "#e2331c", "#262e38", "#efba31"];
const disabledColors = ["#9c9d9e", "#8a8b8c", "#828384", "#656667", "#babbbc"];

const data = ref({
  labels: labels.value,
  datasets: [
    {
      data: Object.values(props.chartData),
      backgroundColor: props.enabled ? enabledColors : disabledColors,
    },
  ],
});

const sampleBarConfig = {
  id: index.value,
  type: "doughnut",
  data: data.value,
  options: {
    cutoutPercentage: 60,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    responsive: false,
    maintainAspectRatio: true,
    legend: {
      display: true,
      position: "right",
    },
    aspectRatio: 0 / 3,
  },
};

const chartObj = ref(null);
const chartConfig = ref(sampleBarConfig);
onMounted(() => {
  chartObj.value = new Chart(
    document.getElementById(sampleBarConfig.id),
    chartConfig.value
  );
});
</script>
