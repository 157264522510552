<template>
  <div class="relative overflow-x-auto bg-white">
    <transition appear mode="in-out" v-if="props.isLoading">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              v-for="(key, id) in 40"
              :key="id"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider animate-pulse"
            >
              <div class="h-4 bg-gray-300 rounded w-1/2"></div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr>
            <td
              v-for="(key, id) in 40"
              :key="id"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider animate-pulse"
            >
              <div class="h-4 bg-gray-300 rounded w-1/2"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </transition>
    <table class="table-auto text-sm text-left" v-else>
      <thead class="text-sm text-teal border">
        <tr>
          <th
            class="text-white bg-teal sticky left-0 bg-opacity-100 px-6 py-4 border overflow-hidden whitespace-nowrap text-ellipsis"
          >
            Practice List
          </th>

          <th
            class="p-4 border"
            v-for="(header, index) in props?.tableHeadings"
            :key="index"
          >
            <div
              class="truncate cursor-pointer text-center"
              :class="props?.tableHeadings.length > 13 ? 'w-16' : 'w-40'"
              @mouseover="mouseOverHeader(index)"
              @mouseleave="hoverHeader[index] = false"
            >
              {{ header.activity }}
            </div>

            <div class="absolute z-10 w-10">
              <Tooltip
                :text="header.activity"
                position="bottom"
                v-show="hoverHeader[index]"
              />
            </div>
          </th>
        </tr>
      </thead>
      <transition appear mode="in-out">
        <tbody v-show="tableData?.length > 0">
          <tr
            class="border-b whitespace-nowrap"
            v-for="(data, id) in tableData"
            :key="id"
          >
            <td
              class="sticky left-0 px-8 py-4 border-r bg-white z-20"
              v-show="data?.submissions?.length > 0"
            >
              <span
                class="py-2 cursor-pointer"
                @click="
                  emitPracticeId(
                    data?.id,
                    data?.practice_name,
                    tableData[id]?.submissions[1]?.instance
                  )
                "
                >{{ data?.practice_name }}</span
              >
            </td>
            <template v-for="(value, key) of data?.submissions" :key="key">
              <td
                class="py-5 border-r text-center capitalize"
                :class="
                  id % 2 === 0
                    ? value?.answer?.includes('no') ||
                      value?.answer > value?.activity?.thresholds[0]?.threshold
                      ? 'bg-red-50'
                      : 'bg-white'
                    : value?.answer?.includes('no') ||
                      value?.answer > value?.activity?.thresholds[0]?.threshold
                    ? 'bg-red-50'
                    : 'bg-teal bg-opacity-10  '
                "
              >
                {{ value?.answer ? value?.answer : "--" }}
                <span
                  v-if="value?.activity?.thresholds?.length > 0"
                  class="text-teal"
                >
                  ({{
                    value?.activity?.thresholds[0]?.threshold >= 0
                      ? value?.activity?.thresholds[0]?.threshold
                      : "--"
                  }})
                </span>
                <div class="flex justify-center">
                  <div
                    class="w-2 h-2 bg-teal rounded-full relative cursor-pointer"
                    @mouseover="hover[id][key] = true"
                    @mouseleave="hover[id][key] = false"
                    v-show="value.answer === 'no'"
                  >
                    <Tooltip
                      :text="value.reason"
                      position="top"
                      v-show="hover[id][key]"
                    />
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </transition>
    </table>
    <div v-if="!props.isLoading">
      <div
        v-if="tableData?.length === 0"
        class="flex flex-col items-center justify-center text-center w-full py-10"
      >
        <div class="rounded-full p-12 icon-bg">
          <img src="../../../assets/icons/noData.svg" class="w-32" />
        </div>
        <div class="mt-4 text-teal font-medium flex align-middle">
          <i class="material-icons text-teal w-8">info_outlined</i>
          No Submissions Found Against This Shift
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref, defineEmits, watch } from "vue";
import Tooltip from "../atoms/Tooltip.vue";

const hover = ref([]);
const hoverHeader = ref([]);

const emit = defineEmits(["practice-clicked"]);

const emitPracticeId = (id, practiceName, instanceId) => {
  emit("practice-clicked", id, practiceName, instanceId);
};

const mouseOverHeader = (id) => {
  hoverHeader.value[id] = true;
};

const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
  tableHeadings: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
  },
});

const { tableData } = toRefs(props);

watch(tableData, (newData) => {
  hover.value = newData.map(() => []);
});
</script>

<style scoped>
.icon-bg {
  background: rgba(235, 248, 247, 1);
}
</style>
