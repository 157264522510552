<template>
  <div class="flex flex-col gap-8">
    <div>
      <ModelDayChartCard
        :cards="cards"
        @active-Shift="setActiveShift"
        :data="totalStatusCount"
        :key="cards"
      />
    </div>
    <div
      class="p-4 border border-teal rounded-lg text-teal flex justify-center cursor-pointer hover:bg-teal hover:text-white"
      @click="routeToManageThreshold()"
    >
      Manage Threshold
    </div>
    <div>
      <ModelDayShiftDetail
        :activeShift="activeShift"
        @refresh-template="setFlag"
        @date-changed="setDate"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import ModelDayChartCard from "@/components/newUi/baseComponents/ModelDayChartCard.vue";
import ModelDayShiftDetail from "./ModelDayShiftDetail.vue";
import { useRouter } from "vue-router";

/**------------------------------------- */
const router = useRouter();

const cards = ref([
  {
    title: "Morning 8:00am",
  },
  {
    title: "Mid-Day 11:00am",
  },
  {
    title: "Evening 4:00pm",
  },
]);
const activeShift = ref();
const refreshFlag = ref(false);
const selectedDate = ref();
const store = useStore();
const totalStatusCount = computed(() => {
  const count = instanceCount?.value;
  return {
    Submitted: count?.submitted,
    Pending: count?.pending,
    Overdue: count?.overdue,
    Expired: count?.expired,
    Requests: count?.redo + count?.extension,
  };
});
const instanceCount = computed(() => {
  return store.getters["hqModelDay/getInstanceCount"];
});
const setFlag = (flag) => {
  refreshFlag.value = flag;
};
const setDate = (date) => {
  selectedDate.value = date;
};
/**
 * ------------------------------------------------------------------
 * setting the active shift according to the event emitted from child
 * ------------------------------------------------------------------
 */

const setActiveShift = (shift) => {
  activeShift.value = shift + 1;
};

const routeToManageThreshold = () => {
  router.push({ name: "hq-ModelDay-monitoringOfPracticeFunctionsModelDayManagerThreshold" });
};

watchEffect(async () => {
  if (activeShift.value && selectedDate.value) {
    const payload = {
      shift: activeShift.value,
      date: selectedDate.value,
      flag: refreshFlag.value,
    };
    await store.dispatch("hqModelDay/fetchInstanceCount", payload);
  }
});
</script>
